import $ from "jquery"
import Modal from 'bootstrap/js/dist/modal'

(function(){
   
    /**
     * Floater ContactForm
     */
    var myModal = new Modal(document.getElementById('ContactFormModal'), {
        backdrop: 'static', 
        keyboard: false
    })

    $('body').on('click', '.openContactFormButton', function(){
        myModal.toggle();
        $('.write-to-us-floater').css('visibility', 'hidden');
    })

	$('#ContactFormModal').on('click', function(event) {
	
		let eTarget = event.target;
        if( eTarget.classList.contains('modal')  ) {
			myModal.toggle();
			$('.write-to-us-floater').css('visibility', 'hidden');
		}
	});

    $('.btnCloseContactFormModal').on('click', function() {
        $('.write-to-us-floater').css('visibility', 'visible');
    });

    /**
     * Custom Select option
     */
    $('select.dropdown').each(function() {
		var dropdown = $('<div />').addClass('dropdown selectDropdown');

		$(this).wrap(dropdown);

		var label = $('<span />').text($(this).attr('placeholder')).insertAfter($(this));
		var list = $('<ul />');

		$(this).find('option').each(function() {
			list.append($('<li />').append($('<a />').text($(this).text())));
		});

		list.insertAfter($(this));

		if($(this).find('option:selected').length) {
			label.text($(this).find('option:selected').text());
			list.find('li:contains(' + $(this).find('option:selected').text() + ')').addClass('active');
			$(this).parent().addClass('filled');
		}
	});

    $(document).on('click', '.selectDropdown ul li a', function(e) {		
      	e.preventDefault();

		//fix bugs
		$('.selectDropdown ul').css('cursor', 'auto');
		$('.selectDropdown ul').fadeOut();
		$('.dropdown > span').css('color', '#000');
		$('.dropdown > span').focus();
            
		$('.selectDropdown ul li').removeClass('active');

		var dropdown = $(this).parent().parent().parent();
		var active = $(this).parent().hasClass('active');
		var label = active ? dropdown.find('select').attr('placeholder') : $(this).text();

		dropdown.find('option').prop('selected', false);

		dropdown.toggleClass('filled', !active);
		dropdown.children('span').text(label);

		if(!active) {
			dropdown.find('option:contains(' + $(this).text() + ')').prop('selected', true);
			$(this).parent().addClass('active');
		}

		dropdown.removeClass('open');

        //pass the value for submit		
        let subject_val = $(this).text();
        $('#subject2').val(subject_val);
	});

	$('.dropdown > span').on('click', function(e) {
		$('.selectDropdown ul').fadeIn();

		var self = $(this).parent();
		self.toggleClass('open');
	});

	$(document).on('click', function(e) {
		var dropdown = $('.dropdown');
		if(dropdown !== e.target && !dropdown.has(e.target).length) {
			dropdown.removeClass('open');
		}
	});

	document.addEventListener( 'wpcf7mailsent', function( event ) {
		var form_name = document.querySelector('input[name="Name"]').value;
		var form_email = document.querySelector('input[name="Email"]').value;
		var form_subject = document.querySelector('select[name="Subject"]').value;
		var form_message = document.querySelector('textarea[name="Message"]').value;
		var form_consent = document.querySelector('input[name="consent"]').value;

		document.querySelector('form._form #fullname').value = form_name;
		document.querySelector('form._form #email').value = form_email;
		document.querySelector('form._form select[data-name="gambling_lb_subject"]').value = form_subject;
		document.querySelector('form._form textarea[data-name="message"]').value = form_message;
		if (form_consent == '1') {
			$('input[data-name="customconsentgamblinglp2"]').trigger('click');
		}

		document.querySelector('form._form ._submit').click();
	}, false );
	

	$("#commentform .submit").on("click", function() {

		var isLogin = document.body.classList.contains( 'logged-in' );

		var comment_author = '',
			comment_email = '',
			comment_url = '',
			comment_msg = $("#commentform textarea#comment").val(),
			comment_id = $("#commentform input[name=comment_post_ID]").val(),
			comment_parent_id = $("#commentform input[name=comment_parent]").val();

		if(isLogin) {
			comment_author = $("input[name=ct-author]").val();
			comment_email = $("input[name=ct-email]").val()
			comment_url = $("input[name=ct-website]").val();
		}
		else {
			comment_author = $("#commentform input[name=author]").val();
			comment_email = $("#commentform input[name=email]").val();
			comment_url = $("#commentform input[name=url]").val();
		}

		document.querySelector('form._form._form_31 #fullname').value = comment_author;
		document.querySelector('form._form._form_31 #email').value = comment_email;
		document.querySelector('form._form._form_31 input[data-name="website"]').value = comment_url;
		document.querySelector('form._form._form_31 textarea[data-name="comment"]').value = comment_msg;
		document.querySelector('form._form._form_31 input[data-name="comment_post_id"]').value = comment_id;
		document.querySelector('form._form._form_31 input[data-name="comment_parent_id"]').value = comment_parent_id;
		
		document.querySelector('form._form._form_31 ._submit').click();
	});

})()