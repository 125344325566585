import $ from "jquery"
import Modal from 'bootstrap/js/dist/modal'
import Carousel from 'bootstrap/js/dist/Carousel'
import SimpleBar from 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.


(function(){
    if(document.getElementById('solutionSecretModal')){
        var myModal = new Modal(document.getElementById('solutionSecretModal'), {
            keyboard: false
        })
    }
    var solutionSecretCard34Carousel = null // instance of bootstrap Carousel

    function toggleCarousel(){
        var width = (window.innerWidth > 0) ? window.innerWidth : screen.width
        var carouselItems = document.querySelectorAll('#solutionSecretCard34 .item')
        
        if(width < 768){ // less than md
            if(solutionSecretCard34Carousel === null){
                document.querySelector('#solutionSecretCard34').classList.add('carousel', 'slide')
                for(let x = 0; x < carouselItems.length; x++){
                    carouselItems[x].classList.add('carousel-item')
                }
                solutionSecretCard34Carousel = new Carousel(document.querySelector('#solutionSecretCard34'), {
                    interval: 20000,
                    pause: 'hover'
                })
                document.querySelector('#solutionSecretCard34').addEventListener('slide.bs.carousel', function (event) {
                    if(event.to === 1) {
                        $(this).find('.previousSlide').removeAttr('disabled')
                        $(this).find('.nextSlide').attr('disabled', true)
                    }else{
                        $(this).find('.previousSlide').attr('disabled', true)
                        $(this).find('.nextSlide').removeAttr('disabled')
                    }
                })
            }
        }else if(solutionSecretCard34Carousel){
            solutionSecretCard34Carousel.dispose()
            solutionSecretCard34Carousel = null
            document.querySelector('#solutionSecretCard34').classList.remove('carousel')
            document.querySelector('#solutionSecretCard34').classList.remove('slide')
            
            for(let x = 0; x < carouselItems.length; x++){
                carouselItems[x].classList.remove('carousel-item')
            }
        }
    }

    $(window).on("load", function(){      
        $('.page-template-template-home-php').on('click', '.openSolutionSecretButton', function(){
            myModal.toggle()    

            setTimeout(function(){                
                var simpleBar = new SimpleBar(document.getElementById('solutionSecretModalBody'), { autoHide: true });
                console.log('simpleBar', simpleBar);
            }, 2000);

            toggleCarousel();   
        })
        
    })

})()