import $ from "jquery"
import Tab from "bootstrap/js/dist/Tab";
import Collapse from "bootstrap/js/dist/Collapse";

var triggerTabList = [].slice.call(document.querySelectorAll('#tabs a'));

triggerTabList.forEach(function (triggerEl) {
    var tabTrigger = new Tab(triggerEl)

    triggerEl.addEventListener('click', function (event) {
        event.preventDefault();
        tabTrigger.show();
        
        //add anchor to url
        let linkHref = "";
        let splitter = triggerEl.toString().split('#');
        let currentUrl = splitter[0],
            currentAnchor = splitter[1];
            
        //if( !$('#pane' + currentAnchor ).length ) {
            linkHref = currentAnchor;
            window.location.href = triggerEl.toString();
        //}

    })
});
