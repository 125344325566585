// fixed header script

window.addEventListener("load", fixedHeader);

window.onscroll = function() {
    fixedHeader();
};

const header = document.querySelector('.header')

function fixedHeader() {
    if (window.pageYOffset > 0) {
        header.classList.add('header--fixed')
    } else {
        header.classList.remove('header--fixed')
    }
}
