import $ from "jquery"
/* eslint-disable no-alert, no-console */
import 'bootstrap/js/dist/Dropdown'

(function() {
    function gotoHeader(headerID){
        ($('.privacy-sidebar-menu[href="#' + headerID + '"]')[0]).click()
    }
    function updateSidebar(){
        var policies = document.getElementsByClassName('privacy-policy-container')
        for(let x = 0; x < policies.length; x++){
            if($(policies[x]).isInViewport()){
                let menus = document.getElementsByClassName('privacy-sidebar-menu')
                for(let x = 0; x < menus.length; x++){
                    menus[x].classList.remove('active')
                }
                let policyId = ($(policies[x]).attr('id')).replace('container', 'policy');
                console.log('policyId', policyId);
                ($('.privacy-sidebar-menu[href="#' + policyId + '"]')).addClass('active');
                $('#privacyPolicyContainer button .privacy-policy-dropdown-menu-label').text((policyId.replace(/-/g, ' ')).replace('policy', ''))
                break;
            }
            
        }
    }
    $(window).on("load", function(){
        if($('.page-template-template-privacy').length === 0){
            return true
        }
        $.fn.isInViewport = function() {
            var elementTop = $(this).offset().top;
            var elementBottom = elementTop + $(this).outerHeight();
        
            var viewportTop = $(window).scrollTop() + 300;
            var viewportBottom = viewportTop + $(window).height();
        
            return elementBottom > viewportTop && elementTop < viewportBottom;
        };
        var scrollTimeout = null
        document.addEventListener('scroll', function(){
            clearTimeout(scrollTimeout)
            scrollTimeout = setTimeout(function(){
                updateSidebar()
                clearTimeout(scrollTimeout)
            }, 150)
        })
        setTimeout(function(){
            updateSidebar()
            $('.privacy-policy-dropdown-menu').css('top', $('header.header').height())
            let url = window.location.href
            if(url.indexOf('#') > -1) {
                gotoHeader((url.split('#'))[1])
            }
        }, 250)
        $(window).on('resize', function() {
            console.log('resized')
            $('.privacy-policy-dropdown-menu').css('top', $('header.header').height())
        })
        $('.privacy-container').on('click', '.privacy-sidebar-menu', function(){
            $('.privacy-sidebar-menu').removeClass('active')
            $(this).addClass('active')
        })
        
    })
}())