import $ from "jquery"

/**
 * Scroll
 */
var scrollAnimate = (anchor) => {
    
    $('html,body').animate({ 
        scrollTop: $('#' + anchor + '-section').offset().top - 30
    }, 100);
}

$('.menu-item a').on('click', function(e) {
    e.preventDefault();

    let link = $(this).data('menu-anchor');
    let anchor = link.split('#');

    scrollAnimate(anchor[1]);
});

/**
 * mobile
 */
$('#nav').on('click', '.menu-item a, .nav-toggle-close', function(e) {
    let nav = $('#nav'),
        navToggle = $('.js-hamburger')

        nav.removeClass('expanded');
        navToggle.removeClass('expanded');
});

$('.js-giveit-btn').on('click', function(e) {
    e.preventDefault();

    let anchor = 'contact';
    scrollAnimate(anchor);
});

$('.js-about-btn').on('click', function(e) {
    e.preventDefault();

    let anchor = 'portfolio';
    scrollAnimate(anchor);
});

$('.js-services-btn').on('click', function(e) {
    e.preventDefault();

    let anchor = 'portfolio';
    scrollAnimate(anchor);
});

/***
 * anchor
*/
$('.home .menu-item:first-child a').addClass('active');

$('.menu-item').on('click', function(e) {
    e.preventDefault();

    let link = $(this).children().attr('href'),
        splitter = link.split('#');
    let currentUrl = window.location.href,
        splitterCurrent = currentUrl.split('#');
    let linkHref = "";

    if(splitter.length > 1) {
        if( $('#' + splitterCurrent[1] + '-section' ).length ) {
            linkHref = splitterCurrent[0] + '#' + splitter[1];
            window.location.href = linkHref;
         
        } else {        
            linkHref = splitter[0] + '#' + splitter[1];
            window.location.href = linkHref;
        }
    }
    else {
        window.location.href = link;
    }
   
   //history.replaceState({}, document.title, window.location.href.split('#')[0]);
});

function updateMenuClass(){
    var sections = $('section'); 
    
    let menus = $('.menu-item a');
    for(let x = 0; x < menus.length; x++){
        menus[x].classList.remove('active')
    }
    
    for(let x = 0; x < sections.length; x++){
        if($(sections[x]).isInViewport()){

            let sectionId = ($(sections[x]).attr('id')).replace('-section', '');
            let hashLink = ($(menus[0]).attr('data-menu-anchor')).split('#')
            let urlLink = hashLink[0] + '#' +sectionId;

            ($('.menu-item a[href="' + urlLink + '"]')).addClass('active');
            break;
        }
        
    }
}

/**
 * onload
 */
$(window).on('load', function() {
    let link = $(location).attr('href');    
    let splitter = link.split('#');

    if( $('#' + splitter[1] + '-section' ).length ) {
        scrollAnimate(splitter[1]);
    }    

    $.fn.isInViewport = function() {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight();
    
        var viewportTop = $(window).scrollTop() + 300;
        var viewportBottom = viewportTop + $(window).height();
    
        return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    var scrollTimeout = null
    document.addEventListener('scroll', function(){
        clearTimeout(scrollTimeout)
        scrollTimeout = setTimeout(function(){
            updateMenuClass();
            clearTimeout(scrollTimeout);
        }, 150)
    })

    $('#menu-main-header').on('click', '.menu-item', function(){
        var hashLink = ($(this).find('a').attr('data-menu-anchor')).split('#');
        $('#menu-main-header .menu-item a').removeClass('active');

        if(hashLink.length === 2){
            if($('#' + hashLink[1] + '-section').length){
                scrollAnimate(hashLink[1]);
                $(this).find('a').addClass('active');
            }else{
                window.location = $(this).find('a').attr('data-menu-anchor');
            }
        }
    });

    
    //section services
    let linkServices = $(location).attr('href'),
        splitterServices = link.split('#pane');
    
    if( $('#pane' + splitterServices[1] ).length ) {
        scrollAnimate('services');

        $('#tabs .nav-item > a').removeClass('active');
        $('#' + splitterServices[1] ).addClass('active');

        $('#content .tab-pane').removeClass('active');
        $('#content .tab-pane').removeClass('show');
        $('#content #pane' + splitterServices[1] ).addClass('active');
        $('#content #pane' + splitterServices[1] ).addClass('show');
    } 
    
    /**
     * section portfolio
     */
    //anchor actions
    let containerPortfolio = $('#portfolio-section');
    $('.slick-slide').on('click', '.portfolio-thumb-item', function() {
        let anchorTag = $(this).data('company');
        let query = $('[data-company="'+ anchorTag + '"]');
        let curUrl = location.toString().split('#');
        let linkHref = "";
        
        linkHref = curUrl[0] + '#' + anchorTag;
        window.location.href = linkHref;     
    });    

    //portfolio services icon overlay
    const portfolioServices = $('.js-icon');
    const portfolioThumbs = $('.portfolio-thumbs');
    const portfolioOverlay = $('.portfolio-services-overlay');

    portfolioServices.on('click', function() {
        if (!$(this).hasClass('disable')) {
            portfolioServices.addClass('disable');
            if ($(this).hasClass('active')) {
                portfolioServices.removeClass('disable');
            }
            $(this).toggleClass('active');
            $(this).removeClass('disable');
            $(this).next('.portfolio-services-overlay').toggleClass('active');
            portfolioThumbs.toggleClass('hide');
        }
    });

    portfolioOverlay.on('click', function() {
        if ($(this).hasClass('active')) {
            portfolioServices.removeClass('disable active');
            $(this).removeClass('active');
            portfolioThumbs.removeClass('hide');
        }
    })
});