import $ from "jquery"
import "slick-carousel-latest"

$portfolioSliderImage = $('.portfolio-slider-image').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.portfolio-thumbs, .portfolio-slider-content',
    swipeToSlide: false,
    adaptiveHeight: true
});

$portfolioSliderContent = $('.portfolio-slider-content').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.portfolio-thumbs, .portfolio-slider-image',
    swipeToSlide: false,
    adaptiveHeight: true
});

$portfolioThumbs = $('.portfolio-thumbs').slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    asNavFor: '.portfolio-slider-image, .portfolio-slider-content',
    dots: false,
    arrows: true,
    centerMode: true,
    focusOnSelect: true,
    variableWidth: true,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1
            }
        },
        {
            breakpoint: 992,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 1
            }
        },
        {
            breakpoint: 426,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1
            }
        }
    ]
});

$postSliderImage = $('.post-slider-image').slick({
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: true,
    prevArrow: '',
    nextArrow: '<button type="button" class="slick-next"> </button>',
    responsive: [
        {
            breakpoint: 960,
            settings: {
                slidesToShow: 1,
                prevArrow: '<button type="button" class="slick-prev"> </button>',
            }
        },
    ],
});


$('.js-category-menu-btn').on('click', function(){
    $('.js-category-list').fadeToggle();
});

$('.js-category-list').on('click', function(){
    $('.portfolio-slider-content').slick('refresh');
    $('.portfolio-slider-image').slick('refresh');
    $('.portfolio-thumbs').slick('refresh');
});

$('.js-category-link').each(function(){
    $(this).on('click', function(){
        const textLink = $(this).text();

        $('.js-category-menu-text').text(textLink);
    });
});

$(document).on('mouseup', function(e){
    var container = $(".js-category-list");

    if (!container.is(e.target) && container.has(e.target).length === 0){
        container.hide();
    }
});

// Slick Filter
var $portfolioSliderImage;
var $portfolioSliderContent;
var $portfolioThumbs;
var $slickCacheSliderImage;
var $slickCacheSliderContent;
var $slickCacheThumbs;
// var previousFilter = '';
var currentFilter = 'all';
// var filtered = 'false';
var filterHandler;
var $postSliderImage;

//Portfolio
$('.js-category-list').on('click', '.js-category-link', function(event){
    filterHandler(event.currentTarget.value);
});

//Services
$('.js-services').on('click', '.js-services-item', function(e){
    e.preventDefault();

    let service_item = $(this).data('service');
    let service_item_text = $(this).data('service-name');

    // Handles filter from services to portfolio section
    filterHandler(service_item);

    // Refreshes slider when filtered
    $('.portfolio-slider').slick('refresh');
    $('.portfolio-thumbs').slick('refresh');

    // Change dropdown text
    $('.js-category-menu-text').text(service_item_text);

    // Smooth scroll to portfolio section
    $('html, body').animate({
        scrollTop: $("#portfolio-category-header").offset().top - 140
    }, 500);
});

/**
 *  onload Portfolio
 **/
var scrollAnimate = (anchor) => {    
    $('html,body').animate({ 
        scrollTop: $('#' + anchor + '-section').offset().top - 30
    }, 100);
}
let curURL = location.toString();
let portfolioSplitter = curURL.split('#portfolio-');   
let urlTag = $('[data-company="portfolio-'+ portfolioSplitter[1] +'"]'); 
if( urlTag.length ) {
    scrollAnimate('portfolio');

    let sliderItem = $('.slick-slide .portfolio-slider-item[data-company="portfolio-'+ portfolioSplitter[1] +'"]');
    if( sliderItem.length ) {
        let sliderIndex = sliderItem.parent().parent().data('slick-index');        
        $('.portfolio-slider-image').slick('slickGoTo', sliderIndex);
        $('.portfolio-slider-content').slick('slickGoTo', sliderIndex);
    }
}

/**
 * Filter function for carousel
 * @param  {String} [tag=''] filter string to be applied
 */
filterHandler = function(tag) {
    var query = '[data-tags*="' + tag + '"]';
    var slickSliderImage = $portfolioSliderImage[0].slick;
    var slickSliderContent = $portfolioSliderContent[0].slick;
    var slickThumbs = $portfolioThumbs[0].slick;

    // Removes filter state if cache is active ( indicates a filter is applied).
    // Work around for https://github.com/kenwheeler/slick/issues/3161
    if (slickSliderImage.$slidesCache !== null) {
        slickSliderImage.unload();
        slickSliderImage.$slideTrack.children(slickSliderImage.options.slide).remove();
        $slickCacheSliderImage.appendTo(slickSliderImage.$slideTrack);
        slickSliderImage.reinit();
        slickSliderImage.goTo(0);
    }

    if (slickSliderContent.$slidesCache !== null) {
        slickSliderContent.unload();
        slickSliderContent.$slideTrack.children(slickSliderContent.options.slide).remove();
        $slickCacheSliderContent.appendTo(slickSliderContent.$slideTrack);
        slickSliderContent.reinit();
        slickSliderContent.goTo(0);
    }

    if (slickThumbs.$slidesCache !== null) {
        slickThumbs.unload();
        slickThumbs.$slideTrack.children(slickThumbs.options.slide).remove();
        $slickCacheThumbs.appendTo(slickThumbs.$slideTrack);
        slickThumbs.reinit();
        slickThumbs.goTo(0);
    }

    // Store a deep copy of the original carousel
    $slickCacheSliderImage = slickSliderImage.$slides.clone(true, true);
    $slickCacheSliderContent = slickSliderContent.$slides.clone(true, true);
    $slickCacheThumbs = slickThumbs.$slides.clone(true, true);

    // Store the previous filter for reference
    // previousFilter = currentFilter;

    // If the filter is being removed
    if (tag === '' || tag === 'all') {

        // Store useful properties. Log
        // filtered = false;
        currentFilter = '';

    // A filter is being applied
    } else {
        // Pass custom function to slick to query UI for our target
        slickSliderImage.filterSlides(function(index, element) {
            return $(element).find(query).length > 0;
        });
        slickSliderContent.filterSlides(function(index, element) {
            return $(element).find(query).length > 0;
        });
        slickThumbs.filterSlides(function(index, element) {
            return $(element).find(query).length > 0;
        });

        // Reset slider position
        slickSliderImage.goTo(0);
        slickSliderContent.goTo(0);
        slickThumbs.goTo(0);

        // Store useful properties.
        // filtered = true;
        currentFilter = tag;
    }

    return currentFilter;
}