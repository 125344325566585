// style
import "../scss/styles.scss";

// js
import "./scripts/home.js";
import "./scripts/header.js";
import "./scripts/footer.js";
import "./scripts/slick-carousel.js";
import "./scripts/hamburger.js";
import "./scripts/sections.js";

import "./scripts/page-templates/home/solutions-secret.js";
import "./scripts/page-templates/privacy/privacy-policy.js";

import "./scripts/page-templates/contact/contactform.js";
